
import { Action, Component, Getter, Vue } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  PersonaFeature,
  ReadPersona,
  StripeProductFrequency,
  StripeProductType,
  RegisteredType,
} from '@abby/core-legacy'
import dayjs from 'dayjs'
import { PaymentState } from '~/store/payment'
import AbbyQuestionsPlan from '~/components-legacy/plans/AbbyQuestionsPlan.vue'
import { IAuthUser } from '~/store/auth'
import AbbyNewPricingPlan from '~/components-legacy/plans/AbbyNewPricingPlan.vue'
import AbbyNewComparativePlan from '~/components-legacy/plans/AbbyNewComparativePlan.vue'
import PaymentModal from '~/components-legacy/modals/billingConfiguration/PaymentModal.vue'

@Component({
  components: {
    PaymentModal,
    AbbyNewComparativePlan,
    AbbyNewPricingPlan,
    AbbyQuestionsPlan,
  },
})
export default class AbbyGestionPlans extends Vue {
  @Getter('payment/isAbbyPlansModalOpened') isAbbyPlansModalOpened!: boolean;
  @Getter('payment/callbacks') callbacks!: PaymentState['callbacks'];
  @Action('payment/setAbbyPlansModalOpened') setAbbyPlansModalOpened!: (value: boolean) => Promise<void>;
  @Action('payment/startSubscriptionTrial') startSubscriptionTrial!: (params: { id: StripeProductType, trialMode?: string}) => Promise<void>;
  @Action('payment/extendSubscriptionTrial') extendSubscriptionTrial!: (id: StripeProductType) => Promise<void>;
  @Action('payment/fetchSubscriptions') fetchSubscriptions!: () => Promise<void>;
  @Getter('guide/persona') persona!: ReadPersona | null
  @Getter('auth/hasGodFather') hasGodFather!: boolean
  @Getter('upsells/promoCode') promoCode!: string

  @Getter('auth/user') user!: IAuthUser

  showPaymentCard: boolean = false
  frequency: StripeProductFrequency = StripeProductFrequency.YEAR
  trialLoading: boolean = false
  messageTimeRemainingBeforeEndPromotionDay: any = {};
  messageTimeRemainingBeforeEndTrial: string = '';

  get hasAbbyStart () {
    return this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_START && !this.$planManager.isTrial()
  }

  get hasAbbyCreationStart () {
    return this.$planManager.has(StripeProductType.ABBY_CREATION_START)
  }

  get hasAbbyCreationStartPremium () {
    return this.$planManager.has(StripeProductType.ABBY_CREATION_START_PREMIUM)
  }

  get hasAbbyCreationStartBusiness () {
    return this.$planManager.has(StripeProductType.ABBY_CREATION_START_BUSINESS)
  }

  get hasAbbyPro () {
    return this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO && !this.$planManager.isTrial()
  }

  get hasAbbyBusiness () {
    return this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_BUSINESS && !this.$planManager.isTrial()
  }

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown
  }

  get affiliationUrl () {
    return JSON.parse(this.user.affiliationUrl || '{}') || {}
  }

  get hasMonthFrequency () {
    return this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.MONTH
  }

  get isPromotionDay () {
    const isSubProYearly = this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO && !this.$planManager.isTrial() && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    const isPromotionDay = this.$dayjs().isBetween('2023-11-20', '2023-11-27', 'day', '[]')
    return !isSubProYearly && isPromotionDay
  }

  get promotionDayText () {
    if (this.user.registeredType === RegisteredType.CREATION) {
      if (this.$dayjs().isSame('2023-11-27', 'day')) {
        return 'Économisez 70% sur tous les abonnements avec le code CREA70 ! 🖤'
      } else {
        return 'Économisez 50% sur tous les abonnements avec le code CREA50 ! 🖤'
      }
    } else if (this.$dayjs(this.user.createdAt).isBefore('2022-07-01', 'day')) {
      return 'Économisez 50% sur tous les abonnements avec le code ABBY50 ! 🖤'
    } else if (this.$dayjs().isSame('2023-11-27', 'day')) {
      return 'Économisez 45% sur tous les abonnements avec le code ABBY45 ! 🖤'
    } else {
      return 'Économisez 40% sur tous les abonnements avec le code ABBY40 ! 🖤'
    }
  }

  get specialDayText () {
    if (this.$dayjs().isSame('2023-11-27', 'day')) {
      return 'Cyber Monday'
    } else {
      return 'Black Friday'
    }
  }

  get calendlyLink () {
    const query = {
      email: this.user?.email,
      phone_number: this.user?.phone,
      name: this.user?.fullName,
    }

    const serialize = (obj: any) => {
      return '?' + Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
    }

    const _query = serialize(query)
    if (this.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API)) {
      return `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs-sap/${_query === '?' || _query === '' ? '' : _query || ''}`
    } else {
      return `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs/${_query === '?' || _query === '' ? '' : _query || ''}`
    }
  }

  async succeed () {
    await this.callbacks?.succeed?.()
    this.$help.updateChatData()
    this.$emit('succeed', null)
  }

  get displayOfferBanner () {
    if (this.hasAbbyCreationStart) { return false }
    return (this.canBeginTrial || !this.isTrialExpired) || this.hasGodFather || this.isPromotionDay
  }

  get canBeginTrial () {
    return !this.isTrial && !this.isTrialExpired && !this.hasAbbyPro && !this.hasAbbyStart && !this.hasAbbyBusiness && !this.hasAbbyCreationStart && !this.hasAbbyCreationStartPremium && !this.hasAbbyCreationStartBusiness
  }

  get isTrial () {
    return this.$planManager.isTrial()
  }

  get isTrialExpired () {
    return this.$planManager.isTrialExpired()
  }

  timeRemainingBeforeTrialEnd () {
    const data = this.$planManager.timeRemainingBeforeTrialEnd()
    this.messageTimeRemainingBeforeEndTrial = `${data.days} jours ${data.hours} heures et ${data.minutes} minutes ${data.seconds}`
  }

  timeRemainingPromotionDay () {
    let delta = dayjs('2023-11-28').diff(dayjs(), 'seconds')

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400)
    delta -= days * 86400

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60

    // what's left is seconds
    const seconds = delta % 60 // in theory the modulus is not required

    this.messageTimeRemainingBeforeEndPromotionDay = {
      days,
      hours,
      minutes,
      seconds,
    }
  }

  openPaymentCard (plan: AbbyPlans) {
    this.$store.dispatch('payment/openPaymentModal', { product: plan as unknown as StripeProductType, frequency: this.frequency })
  }

  beforeDestroy () {
    this.$store.dispatch('upsells/setPromoCode', null)
  }

  async startTrial (plan: AbbyPlans) {
    try {
      this.trialLoading = true
      await this.startSubscriptionTrial({ id: plan as unknown as StripeProductType })
      await this.callbacks?.succeed?.()
      this.$help.updateChatData()
      this.$emit('trial-started', null)
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      this.trialLoading = false
    }
  }

  mounted () {
    if (this.isPromotionDay) {
      this.timeRemainingPromotionDay()
      setInterval(this.timeRemainingPromotionDay, 1000)
    } else {
      this.timeRemainingBeforeTrialEnd()
      setInterval(this.timeRemainingBeforeTrialEnd, 1000)
    }
  }
}
