export enum CampaignType {
  CRISP_MESSAGE_LEGAL_OBLIGATIONS = 'crisp_message_legal_obligations',
  FINFROG_INTRUSIVE_MODAL = 'finfrog_intrusive_modal',
  FORMATIONS_CLIENT_COMMUNIQUER_INTRUSIVE_MODAL = 'formations_client_communiquer_intrusive_modal',
  CFE_INTRUSIVE_MODAL = 'cfe_intrusive_modal',
  RESET_BILLING_NUMBER = 'reset_billing_number',
  RESET_BILLING_NUMBER_2023 = 'reset_billing_number_2023',
  SIDEBAR_ACRE_GESTION = 'sidebar_acre_gestion',
  IN_APP_DISCOVERY = 'in_app_discovery',
  DRAWER_NEW_ADVANTAGES_PAGE = 'drawer_new_advantages_page',
  DRAWER_NEW_TASKS_PAGE = 'drawer_new_tasks_page',
  DRAWER_NEW_OPPORTUNITIES_PAGE = 'drawer_new_opportunities_page',
  MIGRATION_ABBY_PLUS_TO_ABBY_CONTACT = 'migration_abby_plus_to_abby_contact',
  FIRST_ARRIVING_IN_CREATION = 'first_arriving_in_creation',
  FIRST_CALCULATION_METHOD_DECLARATION_PAGE = 'first_calculation_method_declaration_page',
  DISPLAY_IMPOTS_CALCULATION_TOOL_DECLARATIONS = 'display_impots_calculation_tool_declarations',
  CREATION_TO_GESTION = 'creation_to_gestion',
  ASK_ACTIVITY_GESTION = 'ask_activity_gestion',
  PACKAGING_ANNOUNCEMENT = 'packaging_announcement',
  NEW_PACKAGING_ANNOUNCEMENT_MODAL = 'new_packaging_announcement_modal',
  USE_STRIPE_PAYMENT_BANNER_BILLING_PAGE = 'use_stripe_payment_banner_billing_page',
  HOW_TO_MAKE_ADVANCE_BILLING_TYPE_CHOICE = 'how_to_make_advance_billing_type_choice',
  SIGNUP_ONBOARDING_PERSONA_FORM = 'signup_onboarding_persona_form',
  DISCOVER_DECLARATION = 'discover_declaration',
  DISCOVER_BILLING = 'discover_billing',
  DISCOVER_OPPORTUNITIES = 'discover_opportunities',
  DISCOVER_TIME_TRACKING = 'discover_time_tracking',
  DISCOVER_EXPORTS = 'discover_exports',
  BETA_INTEGRATIONS = 'beta_integrations',
  EXPIRED_TRIAL = 'expired_trial',
  RESERVE_DEMO_ANALYSE_PAGE = 'reserve_demo_analyse_page',
  RESERVE_DEMO_CREATION_ANALYSE_PAGE = 'reserve_demo_creation_guide_page',
  RESERVE_DEMO_GUIDE_PAGE = 'reserve_demo_guide_page',
  RESERVE_DEMO_CREATION_GUIDE_PAGE = 'reserve_demo_creation_guide_page',
  RESERVE_DEMO_CREATION_TRACKING_PAGE = 'reserve_demo_creation_tracking_page',
  EVENT_INVITATION = 'event_invitation',
  HEAD_EVENT_INVITATION = 'head_event_invitation',
  NEW_EVENTS_FEATURE = 'new_events_feature',
  BILLING_FOOTER_ADDITIONAL_LOGOS = 'billing_footer_additional_logos',
  BILLING_DISPLAY_LEGAL_STATUS = 'billing_display_legal_status',
  BILLING_NOVA_EMA = 'billing_nova_ema',
  FREE_USAGE = 'free_usage',
  CFE_2022_PROMO_ANALYSE_PAGE = 'cfe_2022_promo_analyse_page',
  BETA_ANALYSES = 'beta_analyses',
  CE_BANNER = 'ce_banner',
  REGISTER_CUSTOM_EXPERIENCE = 'register_custom_experience',
  BUY_CE_IN_ADVANCE = 'buy_ce_in_advance',
  NPS_NOTE_1 = 'nps_note_1',
  NPS_NOTE_1_FEEDBACK_BANNER = 'nps_note_1_feedback_banner',
  GOOGLE_REVIEW_BANNER = 'google_review_banner',
  SURVEY_BANK_RECONCILIATION = 'survey_bank_reconciliation',
  DISCOVER_CURRENCIES_BILLING_CREATION = 'discover_currencies_billing_creation',
  COMMUNITY_EVENTS_MENU = 'community_events_menu',
  IDEAS_MENU = 'ideas_menu',
  EASTER_EGG = 'easter_egg',
  EASTER_EGG_PARTICIPATE = 'easter_egg_participate',
  EASTER_EGG_FINISHED = 'easter_egg_finished',
  EASTER_EGG_BANNER = 'easter_egg_banner',
  SHOW_BILLING_ARCHIVE_INFORMATION = 'show_billing_archive_information',
  AUTOMATIC_BILLING_CANCELLATION = 'automatic_billing_cancellation',
  BILLING_FIRST_STEP_ALERT = 'billing_first_step_alert',
  INVOICES_FIRST_STEP_ALERT = 'invoices_first_step_alert',
  ASSETS_FIRST_STEP_ALERT = 'assets_first_step_alert',
  ESTIMATES_FIRST_STEP_ALERT = 'estimates_first_step_alert',
  PURCHASE_ORDERS_FIRST_STEP_ALERT = 'purchase_orders_first_step_alert',
  PRODUCTS_FIRST_STEP_ALERT = 'products_first_step_alert',
  CREATION_FIRST_STEP_ALERT = 'creation_first_step_alert',
  RECURRENCES_FIRST_STEP_ALERT = 'recurrences_first_step_alert',
  CERTIFICATES_FIRST_STEP_ALERT = 'certificates_first_step_alert',
  EMA_DECLARATIONS_FIRST_STEP_ALERT = 'ema_declarations_first_step_alert',
  BILLING_STYLE_SETTINGS_FIRST_STEP_ALERT = 'billing_style_settings_first_step_alert',
  LEGAL_INFORMATIONS_SETTINGS_FIRST_STEP_ALERT = 'legal_informations_settings_first_step_alert',
  ONLINE_PAYMENT_SETTINGS_FIRST_STEP_ALERT = 'online_payment_settings_first_step_alert',
  REMINDER_SETTINGS_FIRST_STEP_ALERT = 'reminder_settings_first_step_alert',
  EMAIL_SETTINGS_FIRST_STEP_ALERT = 'email_settings_first_step_alert',
  INTERNATIONALIZATION_SETTINGS_FIRST_STEP_ALERT = 'internationalization_settings_first_step_alert',
  NUMBER_FORMAT_SETTINGS_FIRST_STEP_ALERT = 'number_format_settings_first_step_alert',
  IMMEDIATE_ADVANCE_FIRST_STEP_ALERT = 'immediate_advance_first_step_alert',
  PRODUCTIVITY_BOARD_FIRST_STEP_ALERT = 'productivity_board_first_step_alert',
  PRODUCTIVITY_TASKS_FIRST_STEP_ALERT = 'productivity_tasks_first_step_alert',
  PRODUCTIVITY_TIME_TRACKING_FIRST_STEP_ALERT = 'productivity_time_tracking_first_step_alert',
  PRODUCTIVITY_BOARD_SETTINGS_FIRST_STEP_ALERT = 'productivity_board_settings_first_step_alert',
  CONTACTS_CUSTOMERS_FIRST_STEP_ALERT = 'contacts_customers_first_step_alert',
  CONTACTS_PROVIDERS_FIRST_STEP_ALERT = 'contacts_providers_first_step_alert',
  OPERATIONS_FIRST_STEP_ALERT = 'operations_first_step_alert',
  BANK_ATTACHMENTS_FIRST_STEP_ALERT = 'bank_attachments_first_step_alert',
  BANK_ATTACHMENTS_DISCOVER = 'bank_attachments_discover',
  RESULTS_FIRST_STEP_ALERT = 'results_first_step_alert',
  RESULTS_DISCOVER = 'results_discover',
  CERTIFICATES_DISCOVER = 'certificates_discover',
  VAT_DISCOVER = 'vat_discover',
  IMMEDIATE_ADVANCE_DISCOVER = 'immediate_advance_discover',
  EMA_DECLARATION_DISCOVER = 'ema_declaration_discover',
  INCOME_STATEMENT_FIRST_STEP_ALERT = 'income_statement_first_step_alert',
  INCOME_STATEMENT_DISCOVER = 'income_statement_discover',
  DECLARATION_VAT_FIRST_STEP_ALERT = 'declaration_vat_first_step_alert',
  ACCOUNTING_INCOMES_FIRST_STEP_ALERT = 'accounting_incomes_first_step_alert',
  ACCOUNTING_EXPENSES_FIRST_STEP_ALERT = 'accounting_expenses_first_step_alert',
  ONLINE_PAYMENTS_FIRST_STEP_ALERT = 'online_payments_first_step_alert',
  ONLINE_SIGNATURES_FIRST_STEP_ALERT = 'online_signatures_first_step_alert',
  REMINDERS_FIRST_STEP_ALERT = 'reminders_first_step_alert',
  PROFILE_SETTINGS_FIRST_STEP_ALERT = 'profile_settings_first_step_alert',
  SECURITY_SETTINGS_FIRST_STEP_ALERT = 'security_settings_first_step_alert',
  SUBSCRIPTION_SETTINGS_FIRST_STEP_ALERT = 'subscription_settings_first_step_alert',
  ACCOUNTING_TURNOVER_SETTINGS_FIRST_STEP_ALERT = 'accounting_turnover_settings_first_step_alert',
  ACCOUNTING_SOCIAL_CONTRIBUTION_SETTINGS_FIRST_STEP_ALERT = 'accounting_social_contribution_settings_first_step_alert',
  ACCOUNTING_VAT_SETTINGS_FIRST_STEP_ALERT = 'accounting_vat_settings_first_step_alert',
  ACCOUNTING_URSSAF_SETTINGS_FIRST_STEP_ALERT = 'accounting_urssaf_settings_first_step_alert',
  ACCOUNTING_BANK_SETTINGS_FIRST_STEP_ALERT = 'accounting_bank_settings_first_step_alert',
  ACCOUNTING_PLAN_SETTINGS_FIRST_STEP_ALERT = 'accounting_plan_settings_first_step_alert',
  INTEGRATION_SETTINGS_FIRST_STEP_ALERT = 'integration_settings_first_step_alert',
  INTEGRATION_FIRST_STEP_ALERT = 'integration_first_step_alert',
  ACCOUNTING_BILLING_CHEQUE_ALERT = 'accounting_billing_cheque_alert',
  BETA_PROGRAM = 'beta_program',
  BETA_PROGRAM_GO = 'beta_program_go',
  BETA_PROGRAM_STEP_2 = 'beta_program_step_2',
  AB_PRICING = 'ab_pricing',
  DOUBLE_2 = 'double_2',
  ONBOARDING_IMMEDIATE_ADVANCE_MODAL = 'onboarding_immediate_advance_modal',
  ONBOARDING_URSSAF_CONNECTION_MODAL = 'onboarding_urssaf_connection_modal',
  OLD_PURCHASE_REGISTER_PAGE = 'old_purchase_register_page',
  BETA_BILLING_V2_GO = 'beta_billing_v2_go',
  BLACK_FRIDAY_2023_OLD_USERS = 'black_friday_2023_old_users',
  WELCOME_VIDEO_AFTER_2_DAYS = 'welcome_video_after_2_days',
  ABBY_50 = 'abby_50',
  EXTENDED_TRIAL_ACTIVE_FREEMIUM_JANUARY_2024 = 'extended_trial_active_freemium_january_2024',
  EXTENDED_TRIAL_ACTIVE_FREEMIUM_JANUARY_2024_ACTIVE = 'extended_trial_active_freemium_january_2024_active',
  WEBINAR_HOME_PAGE = 'webinar_home_page',
  JANUARY_2024_DISCOUNT = 'january_2024_discount',
  JANUARY_2024_DISCOUNT_2 = 'january_2024_discount_2',
  START_1_GESTION = 'start_1_gestion',
  START_2_GESTION = 'start_2_gestion',
  START_3_GESTION = 'start_3_gestion',
  START_4_GESTION = 'start_4_gestion',
  START_5_GESTION = 'start_5_gestion',
  START_3_GESTION_INFORMATION = 'start_3_gestion_information',
  START_3_GESTION_CUSTOMIZE = 'start_3_gestion_customize',
  START_4_GESTION_INFORMATION = 'start_4_gestion_information',
  START_4_GESTION_CUSTOMIZE = 'start_4_gestion_customize',
  START_5_GESTION_INFORMATION = 'start_5_gestion_information',
  START_5_GESTION_CUSTOMIZE = 'start_5_gestion_customize',
  FEBRUARY_2024_DISCOUNT = 'february_2024_discount',
  RELOGGED_CAMPAIGN = 'relogged_campaign',
  HOME_GUIDE_BILLING_ENDED = 'home_guide_billing_ended',
  HOME_GUIDE_GENERAL_ENDED = 'home_guide_general_ended',
  BILLING_SETUP_STEPS_ENDED = 'billing_setup_steps_ended',
  APP_MOBILE_SURVEY_HEAD_BANNER = 'app_mobile_survey_head_banner',
  IMPOTS_CHATBOT_2024_HEAD_BANNER = 'impots_chatbot_2024_head_banner',
  UPDATE_TAX_RATE_JULY_2024 = 'update_tax_rate_july_2024',
  ALREADY_GO_TO_LEGAL_PLACE = 'already_go_to_legal_place',
  HIDE_CREATION_FOLLOWUP = 'hide_creation_followup',
  ABBY_BUSINESS_LAUNCH = 'abby_business_launch',
  USERS_ADD_USERS_FIRST_STEP_ALERT = 'users_add_users_first_step_alert',
}
