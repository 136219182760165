import { Context, Plugin } from '@nuxt/types'
import {
  AbbyPlans,
  ABGroupPricing,
  has,
  hasAccessTo,
  ABBY_FREE_MAX_CONTACTS,
  isExtendedTrial,
  isLockedBecauseOfFailedPayment,
  isSuspended,
  isTrial,
  isTrialExpired,
  PlanFeature,
  ReadCompanyStripeProduct,
  SegmentEvent,
  StripeProductFrequency,
  StripeProductType,
  timeRemainingBeforeTrialEnd,
  whichPlanCompanyHas,
  whichPlanFor,
  whichPlanHasExpired as _whichPlanHasExpired,
  whichPlansSupport,
  ICompany,
  productionPricingWithoutTax,
  ABBY_FREE_MAX_PRODUCTS, canExtendTrial,
} from '@abby/core-legacy'

import { $enum } from 'ts-enum-util'

import { Action } from '@abby/shared'
import { PaymentCallbacks, PaymentParams } from '~/store/payment'

export interface FailedInformation {
  failedBillingDueDate?: Date | null;
  lastFailedAt?: Date | null;
  failedInvoiceUrl?: string | null;
}

export type AccessOptions = {
  hasVat?: boolean
  totalBankAccountsSynchronized?: number
}

export interface PlanManagerInstance {
  features: {
    [K in PlanFeature]: PlanFeature;
  },
  plans: {
    [K in AbbyPlans]: AbbyPlans;
  },
  has: (plans: StripeProductType | StripeProductType[] | null | undefined) => boolean;
  isTrial: () => boolean;
  isExtendedTrial: (productId: StripeProductType) => boolean;
  canExtendTrial: () => boolean;
  isTrialExpired: () => boolean;
  isExtendedTrialExpired: (productId: StripeProductType) => boolean;
  isSuspended: (productId: StripeProductType) => boolean;
  displayAbbyCreationPlans: () => boolean;
  whichPlanCompanyHas: () => AbbyPlans;
  whichFrequencyPlanCompanyHas: () => StripeProductFrequency | null;
  whichPlanCompanyHasExpired: () => AbbyPlans;
  companyPlan: () => ReadCompanyStripeProduct | null;
  hasAccessTo: (feature: PlanFeature, overrideData?: AccessOptions) => boolean
  hasPaymentFailed: () => boolean;
  hasRelatedCESubscription: () => boolean;
  hasSoonExpiredPaymentMethod: () => boolean;
  isLockedBecauseOfFailedPayment: () => boolean;
  whichPlanHasExpired: () => AbbyPlans | null;
  hasExpiredPaymentMethod: () => boolean;
  failedInformation: () => FailedInformation;
  hasSubscribedFor: (feature: PlanFeature) => boolean
  whichPlanFor: (feature: PlanFeature) => StripeProductType
  whichPlansSupport: (feature: PlanFeature) => StripeProductType[]
  isTrialExpiredFor: (feature: PlanFeature) => StripeProductType
  openAbbyPlansModal: ({ feature, callbacks, params, openPlan }: { feature: PlanFeature, callbacks?: PaymentCallbacks, params?: PaymentParams, openPlan?: boolean }) => Promise<void> | void
  openExpiredTrialModal: () => Promise<void> | void
  closeExpiredTrialModal: () => Promise<void> | void
  abGroupPricing: () => ABGroupPricing
  timeRemainingBeforeTrialEnd: () => { days: number, hours: number, minutes: number, seconds: number }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PlanManagerPlugin: Plugin = ({ store, app, $site, $config, $dayjs, $ap, $move, $campaignsManager, $hotjar }: Context, inject) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const products = (): ReadCompanyStripeProduct[] => store.getters['company/plans']
  const paymentMethods = (): any[] => store.getters['company/paymentMethods']
  const company = (): ICompany => store.getters['company/company']
  const totalOpportunities = (): number => store.getters['opportunity/totalCount']
  const totalTimeRecords = (): number => store.getters['timeTracking/totalCount']
  const isInCreation = (): ICompany => store.getters['company/isInCreation']

  const hasPlan = (plans: StripeProductType | StripeProductType[]) => {
    return has(products(), plans)
  }

  const _whichPlanCompanyHas = (): AbbyPlans => {
    return whichPlanCompanyHas(products())
  }

  const _whichPlanFor = (feature: PlanFeature) => {
    return whichPlanFor(feature, { createdAt: company()?.createdAt || undefined })
  }

  const _whichPlansSupport = (feature: PlanFeature) => {
    return whichPlansSupport(feature)
  }

  const _isTrialExpired = (): boolean | undefined => {
    return isTrialExpired(products())
  }

  const _hasPaymentFailed = () => {
    const plan = _whichPlanCompanyHas() as unknown as StripeProductType
    const product = products().find(p => p.productId === plan && !p.deletedAt)
    const ce = products().find(p => p.productId === StripeProductType.ABBY_CE && !p.deletedAt)
    return product?.lastFailedAt || ce?.lastFailedAt
  }

  const _isLockedBecauseOfFailedPayment = () => {
    return isLockedBecauseOfFailedPayment(products())
  }

  const manager = {
    features: $enum(PlanFeature).getValues().reduce((acc, v) => ({ ...acc, [v]: v }), {}),
    plans: $enum(AbbyPlans).getValues().reduce((acc, v) => ({ ...acc, [v]: v }), {}),
    has: hasPlan,
    companyPlan () {
      const plan = _whichPlanCompanyHas()
      return products()?.find(p => p.productId === plan as unknown as StripeProductType && !p.deletedAt)
    },
    isTrial () {
      const plan = _whichPlanCompanyHas() as unknown as StripeProductType
      return isTrial(products(), plan)
    },
    isLockedBecauseOfFailedPayment () {
      return _isLockedBecauseOfFailedPayment()
    },
    hasPaymentFailed () {
      return _hasPaymentFailed()
    },
    failedInformation (): FailedInformation {
      const plan = _whichPlanCompanyHas() as unknown as StripeProductType
      const product = products().find(p => p.productId === plan)
      const ce = products().find(p => p.productId === StripeProductType.ABBY_CE)
      return {
        failedBillingDueDate: product?.failedBillingDueDate || ce?.failedBillingDueDate,
        lastFailedAt: product?.lastFailedAt || ce?.lastFailedAt,
        failedInvoiceUrl: product?.failedInvoiceUrl || ce?.failedInvoiceUrl,
      }
    },
    hasExpiredPaymentMethod () {
      return paymentMethods()?.some((pm) => {
        const expireMonth = pm.exp_month
        const expireYear = pm.exp_year
        return $dayjs(`${expireMonth}-${expireYear}`, 'M-YYYY').diff(undefined, 'd') <= 0
      })
    },
    hasSoonExpiredPaymentMethod () {
      return paymentMethods()?.some((pm) => {
        const expireMonth = pm.exp_month
        const expireYear = pm.exp_year
        return $dayjs(`${expireMonth}-${expireYear}`, 'M-YYYY').diff(undefined, 'd') < 45
      })
    },
    isExtendedTrial (productId: StripeProductType) {
      return isExtendedTrial(products(), productId)
    },
    canExtendTrial () {
      return canExtendTrial(products())
    },
    whichPlanHasExpired () {
      return _whichPlanHasExpired(products())
    },
    isTrialExpired: _isTrialExpired,
    isExtendedTrialExpired () {
      return isTrialExpired(products())
    },
    isSuspended (productId: StripeProductType) {
      return isSuspended(products(), productId)
    },
    hasCourseAccess (plan: StripeProductType) {
      const coursesPlan = [
        StripeProductType.ABBY_ACADEMIE_MARKETING_COMMUNICATION,
        StripeProductType.ABBY_ACADEMIE_CREATION,
      ]

      if (!coursesPlan.includes(plan)) {
        return false
      }

      const access = {
        [StripeProductType.ABBY_ACADEMIE_MARKETING_COMMUNICATION]: this.has(plan),
        [StripeProductType.ABBY_ACADEMIE_CREATION]: this.has(plan) || (this.has(StripeProductType.ABBY_PLUS) || this.has(StripeProductType.ABBY_CONTACT)),
      }
      // @ts-ignore
      return access[plan] || false
    },
    hasAccessTo (feature: PlanFeature, overrideData?: AccessOptions): boolean {
      const _company = company()
      const _totalOpportunities = totalOpportunities()
      const _totalTimeRecords = totalTimeRecords()

      return hasAccessTo(products(), feature, {
        createdAt: _company?.createdAt || undefined,
        additionalAllowedFeature: _company?.additionalAllowedFeature,
        totalOpportunities: _totalOpportunities,
        totalTimeRecords: _totalTimeRecords,
        hasVat: !!_company?.hasVat,
        ...overrideData,
      })
    },
    hasSubscribedFor (feature: PlanFeature): boolean {
      const _company = company()
      const plan = _whichPlanFor(feature)
      return hasAccessTo(products(), feature, { createdAt: _company?.createdAt || undefined, additionalAllowedFeature: _company.additionalAllowedFeature }) && !isTrial(products(), plan)
    },
    whichPlanCompanyHas: _whichPlanCompanyHas,
    whichPlanFor: _whichPlanFor,
    whichPlansSupport: _whichPlansSupport,
    isTrialExpiredFor (feature: PlanFeature): boolean {
      return !!_isTrialExpired()
    },
    whichPlanCompanyHasExpired (): AbbyPlans | null {
      // TODO: cette fonction retourner toujours Abby Business car la fonction pure ne permet pas de faire la nuance
      if (isTrialExpired(products())) { return AbbyPlans.ABBY_BUSINESS }
      return null
    },
    displayAbbyCreationPlans: () => {
      return isInCreation() && !manager.hasAccessTo(PlanFeature.CREATION_CREATE_MICRO)
    },
    timeRemainingBeforeTrialEnd: () => {
      return timeRemainingBeforeTrialEnd(products())
    },
  }

  inject('planManager', {
    ...manager,
    hasRelatedCESubscription () {
      const plan = manager.companyPlan()
      const cePlan = products().find(p => p.productId === StripeProductType.ABBY_CE && !p.deletedAt)
      return (plan && cePlan && plan.subscriptionId === cePlan.subscriptionId)
    },
    whichFrequencyPlanCompanyHas () {
      return manager.companyPlan()?.frequency || null
    },
    openAbbyPlansModal: async ({ callbacks, params, feature, openPlan }: { callbacks?: PaymentCallbacks, params?: PaymentParams, feature?: PlanFeature, openPlan?: boolean }) => {
      if (!openPlan) {
        $ap.sendSegmentTrack({
          event: SegmentEvent.SALE_FEATURE_MODAL_OPENED,
          data: {
            feature,
          },
        })
        $ap.segmentTrack(SegmentEvent.SALE_FEATURE_MODAL_OPENED, { feature })
        $ap.track(Action.MODAL_OPENED, { feature, modal: 'SALE_FEATURE' })
        await store.dispatch('payment/openAbbySaleFeatureModal', { callbacks, params, feature: feature || null })
        return
      }
      $hotjar.sendHotjarEvent(SegmentEvent.PLANS_MODAL_OPENED as string)
      $ap.sendSegmentTrack({
        event: SegmentEvent.PLANS_MODAL_OPENED,
        data: {
          feature,
        },
      })
      $ap.track(Action.MODAL_OPENED, { feature, modal: 'PRICING_PLAN' })
      $ap.segmentTrack(SegmentEvent.PLANS_MODAL_OPENED, { feature })
      await store.dispatch('payment/openAbbyPlansModal', { callbacks, params })
    },
    openExpiredTrialModal: async () => {
      $ap.segmentTrack(SegmentEvent.EXPIRED_TRIAL_MODAL_OPENED, {})
      await store.dispatch('payment/openExpiredTrialModal')
    },
    closeExpiredTrialModal: async () => {
      await store.dispatch('payment/closeExpiredTrialModal')
    },
    abGroupPricing: () => {
      return ABGroupPricing.DEFAULT
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $planManager: PlanManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $planManager: PlanManagerInstance
  }
  interface Context {
    $planManager: PlanManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $planManager: PlanManagerInstance
  }
}

export default PlanManagerPlugin
export { ABBY_FREE_MAX_PRODUCTS }

export { ABBY_FREE_MAX_CONTACTS }
