import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { useContext } from '@nuxtjs/composition-api'
import axios from 'axios'
import {
  PersonalServiceActivities,
  CreateProduct,
  ReadProductListItem,
  UpdateProduct,
  Vat,
  CreatePersonalServiceActivity,
  UpdatePersonalServiceActivity,
  Prices,
  Money,
  CurrencyCode,
  CatalogOrderBy,
  OrderDirection,
} from '@abby/shared'

import { Ref } from 'vue'
import { ProductItem } from '~/logic/contexts/product/domain/entity/productItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export type ProductFilterQuery = {
  search: Ref<string | undefined>,
}

export type ProductPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
  orderBy: Ref<CatalogOrderBy>,
  orderDirection: Ref<OrderDirection>,
} & ProductFilterQuery

export const useProductRepository = () => {
  const queryClient = useQueryClient()
  const alertManager = useAlertManager()
  const { $backend, i18n } = useContext()

  const productItemMap = (doc: ReadProductListItem) => {
    const firstMajLetter = (word: string) => {
      return word.length > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    }

    return ProductItem.create({
      id: doc.id,
      designation: doc.designation,
      description: doc.description,
      reference: doc.reference,
      personalServiceActivity: PersonalServiceActivities.find(item => item.value === doc.personalServiceActivity)?.label || null,
      type: i18n.t(`productTypes.${doc.type.toUpperCase()}`) as string,
      unit: firstMajLetter(i18n.t(`productUnits.${doc.unit.toUpperCase()}`) as string),
      vatText: Vat.getVatItem(doc.vatCode)?.label || null,
      isDeliveryOfGood: doc.isDeliveryOfGood,
      unitPrice: Money.create({ amountInCents: doc.unitPrice }).format(CurrencyCode.EUR, { keepExraDecimals: true }),
      priceWithTaxes: Money.create({ amountInCents: doc.priceWithTaxes }).format(),
      priceWithoutTaxes: Money.create({ amountInCents: doc.priceWithoutTaxes }).format(),
      vatAmount: doc.vatAmount,
      taxesIncluded: doc.taxesIncluded,
    })
  }

  const paginate = ({ page, limit, search, orderBy, orderDirection }: ProductPaginateQuery) => {
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['product', { page, limit, search, orderBy, orderDirection }],
      queryFn: async ({ signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        const data = await $backend.product.paginate({
          page: page.value,
          search: search.value?.length ? search.value : undefined,
          limit: limit.value,
          orderBy: orderBy?.value,
          orderDirection: orderDirection?.value,
          cancelToken: source.token,
        })
        return {
          ...data,
          docs: data.docs.map(productItemMap),
        }
      },
      onError: (error) => {
        alertManager.autoError(error)
      },
      keepPreviousData: true,
    })
  }

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries(['product'])
  }

  const getProductById = async (id: string) => {
    return await $backend.product.fetchProduct(id)
  }

  const { mutateAsync: createProduct } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (newProduct: CreateProduct) => $backend.product.createProduct(newProduct),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: updateProduct } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (productToUpdate: UpdateProduct) => $backend.product.updateProduct(productToUpdate),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: createProductPersonalServiceActivity } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (newProduct: CreatePersonalServiceActivity) => $backend.product.createProductPersonalServiceActivity(newProduct),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: updateProductPersonalServiceActivity } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (productToUpdate: UpdatePersonalServiceActivity) => $backend.product.updateProductPersonalServiceActivity(productToUpdate),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const removeProduct = async (id: string) => {
    await $backend.product.removeProduct(id)
    await refreshPaginate()
  }

  return {
    paginate,
    refreshPaginate,
    getProductById,
    createProduct,
    updateProduct,
    createProductPersonalServiceActivity,
    updateProductPersonalServiceActivity,
    removeProduct,
  }
}
