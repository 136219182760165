
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { toArray } from '@abby/core-legacy'
import Link from '~/components-legacy/utils/Link.vue'

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const SIZE_1_MO = 1024 * 1024
const MAX_FILE_SIZE_IN_MO = 10
const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MO * SIZE_1_MO

@Component({
  components: { Link },
})
export default class ASDropzone2 extends Vue {
  @ModelSync('value', 'input')
  file?: File | (File | null | undefined)[] | null

  isDragging = false

  @Prop({ default: 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/gif, image/png, image/jpeg, image/bmp, image/webp' })
  accept!: string;

  @Prop({ required: false, default: false })
  multiple!: any;

  @Prop()
  placeholder?: string;

  @Prop()
  minHeight?: number;

  @Prop()
  maxHeight?: number;

  @Prop()
  imageMaxHeight?: number;

  @Prop()
  imgUrl?: string;

  setFile (file: File | (File | null | undefined)[] | null) {
    if (!file) { this.file = null }
    const accept = this.accept.split(',').map(m => m.trim())

    const filesTooLarge = toArray(file).filter(({ size }) => size >= MAX_FILE_SIZE)

    if (filesTooLarge.length) {
      (this.$refs.fileInput as any).value = ''
      this.$alertsManager.error(
        this.$tc('error.file.too_large', filesTooLarge.length, { file: filesTooLarge[0].name, maxSize: `${MAX_FILE_SIZE_IN_MO} Mo` }).toString(),
      )
      return
    }

    const mimeTypeAccepted = toArray(file).every(({ type }) => accept.includes(type))
    if (!mimeTypeAccepted) {
      this.$alertsManager.error('Le type de fichier n\'est pas valide')
      return
    }
    this.$nextTick(() => {
      this.file = file
    })
  }

  get uploadMessage () {
    if (this.placeholder) { return this.placeholder }
    return this.multiple ? 'Glissez et déposez vos fichiers' : 'Glissez et déposez votre fichier'
  }

  mimeTypeURL (mimeType: string) {
    return this.$file.getMimeTypeURL(mimeType)
  }

  openFileSelect () {
    (this.$refs.fileInput as any).click()
  }

  handleDragOver (e: HTMLInputEvent) {
    e.preventDefault()
  }

  get filesToDisplay () {
    if (Array.isArray(this.file)) {
      return this.file
    } else if (this.file) {
      return [this.file]
    } else {
      return []
    }
  }

  onFileChanged (e: HTMLInputEvent) {
    e.preventDefault()
    if (this.multiple) {
      this.setFile([
        ...(Array.isArray(this.file) ? this.file : [this.file]),
        ...Array.from(e.target?.files || []),
      ])
      return
    }
    this.setFile(e.target?.files?.[0] || null)
    this.$emit('input', this.file)
  }

  handleDrop (ev: DragEvent) {
    ev.preventDefault()
    if (this.multiple) {
      if (ev.dataTransfer?.items) {
        this.setFile([
          ...(Array.isArray(this.file) ? this.file : [this.file]),
          ...Array.from(ev.dataTransfer.items).map(item => item.getAsFile() as File),
        ])
      }

      if (ev.dataTransfer?.files) {
        this.setFile([
          ...(Array.isArray(this.file) ? this.file : [this.file]),
          ...Array.from(ev.dataTransfer.files),
        ])
      }
      this.isDragging = false
      return
    }

    if (ev.dataTransfer?.items) {
      this.setFile(ev.dataTransfer.items[0].getAsFile())
    }

    if (ev.dataTransfer?.files) {
      this.setFile(ev.dataTransfer.files[0])
    }

    this.isDragging = false
  }

  deleteFile (i: number) {
    if (Array.isArray(this.file)) {
      this.file.splice(i, 1)
    } else {
      this.file = null
    }
  }

  dragLeave (ev: DragEvent) {
    if ((ev.currentTarget as any)?.contains(ev.relatedTarget)) {
      return
    }
    this.isDragging = false
  }

  get maxFileSizeInMo () {
    return MAX_FILE_SIZE_IN_MO
  }
}

